.navbar{
    background-color :#fafafa;
    padding: 1% 0;
}
.navbar-brand{
    margin: 0;
}
.nav-link{
    font-size: 1.5rem;
    font-weight: 500;
    color: #800E1D;
}
.nav-link:hover{
    color: #747373;
}
.nav-link:focus{
    color: #800E1D;
}
.nav-brand-containter{
    max-width: 50%;
    margin-left: 1%;
}
.dropdown-toggle:hover{
    color: #747373 !important;
}
.rn-logo{
    width: 70%;
    transition: linear 0.4s;
}
.icon:hover{
    color: #747373;
}
.icon{
    margin: 13.7px 6px;
    font-size: 1.6rem;
    color: #800E1D;
}

@supports (backdrop-filter: blur(.1em)) {
    .navbar{
        background: rgba(250, 250, 250, 0.5);
        backdrop-filter: blur(.5em);
        padding-bottom: 10px;
    }
}

@media (max-width:989px) {

    .rn-logo{
        width: 160%;
    }
    .hide-on-mobile{
        display: none;
    }
    .scrolled .rn-logo {
        width: 120%;
        transition: linear 0.4s;
      }
}

@media (min-width:990px) {

    .hide-on-desktop{
        display: none;
    }
}