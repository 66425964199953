.footer-container{
    background-color: #800E1D;
    padding: 1%;
    
}
.footer-button{
    border-color: #fff;
    background-color: #fff;
    color: #800e1d;
    padding: auto;
    margin: 5% 0;
    padding: 6%;
}

.footer-button:hover,
.footer-button:focus {
    background-color: #f3e1e17a;
    border: #f3e1e17a;
    color: #fff;
}
.copyright{
    font-size: 0.9em;
    color: #fff;
}