.hero-container{
  display: flex;
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
}
.hero{
  position: relative;
  overflow: hidden;
}
.hero-text{
    position: absolute;
    top: 50%; /* Adjust as needed */
    color: #fff;
    left: 50%; /* Adjust as needed */
    transform: translate(-5%, -50%); /* Center the overlay */
    text-align: center; /* Align text in the center */
    background: rgba(10, 10, 10, 0.1);
    backdrop-filter: blur(.5em);
    padding: 1%;
    font-size: 1.5em;
}
.hero-heading{
    position: absolute;
    top: 50%; /* Adjust as needed */
    color: #800e1d;
    left: 50%; /* Adjust as needed */
    transform: translate(-50%, -50%); /* Center the overlay */
    text-align: center; /* Align text in the center */
    background: rgba(151, 151, 151, 0.5);
    backdrop-filter: blur(.5em);
    padding: 2%;
    font-size: 5em;
}
.carf-pos {
  max-width: 100%;
  height: auto;
  position: absolute;
  top: 0; /* Adjust as needed */
  right: 0; /* Adjust as needed */
}
.content-row{
    margin: 3% 0;
    background-color: #f0f0f0;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.2);
}
.content-container{
  margin-top: 2%;
  padding-bottom: 2%;
}
.content-col{
  background: rgba(245, 242, 242, 0.541);
  backdrop-filter: blur(2em);
  height: 100%;
  color: #000;
  padding: 2%;
}
/* Cards */
.grid-card-container{
  margin: 5% 0;
}
.grid-card{
  margin: 1% 0;
  text-align: center;
}
.card{
  background-color: #800e1d;
  color: #fff;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.3);
  height: 100%;
  transition: transform 0.35s ease;
}
.card:hover{
  color: #800e1d;
  background-color: #fff;
  transform: scale(1.1);
}
.card-body{
  height: 100%;
}
.services-button{
  background-color: #800e1d;
  border-color: #800e1d;
  font-size: 1.2em;
  margin: 0 1% 5% 0;
  width: 100% !important;
}
.services-button:hover{
  background-color: #fff;
  color: #800e1d;
  border-color: #800e1d;
  font-weight: 700;
}
/* Contact Form */
.contact-button,
.contact-button:disabled{
  background-color: #800e1d;
  border-color: #800e1d;
  font-size: 1.2em;
  margin: 0 1% 5% 0;
}
.contact-button:hover,
.contact-button:focus{
  background-color: #fff;
  color: #800e1d;
  border-color: #800e1d;
  font-weight: 700;
}
.contact-form{
  padding: 2% 2% 0 2% ;
  background: rgba(10, 10, 10, 0.1);
  backdrop-filter: blur(.5em);
}
.contact-form h2 {
  color: #fff;
}
form{
  margin-top: 5%;
}
.captcha-css{
  padding: 0 0 2%;
}
.contact-numbers{
  color: #fff;
  padding: 2% 2% 0 2% ;
  background: rgba(10, 10, 10, 0.1);
  
  backdrop-filter: blur(.5em);
}
/* Extras */
.Jumbotron{
  background-color: #800e1d;
  color: #fff;
  padding: 2%;
  margin-top: 1%;
}
.textcenter{
  text-align: center;
}
a{
  text-decoration: none;
}
/* Page Backgrounds */
.background-img-props{
  background-size: cover;
  min-width: 100%;
  min-height: 100%;
}


@media (max-width: 768px) {
    .hide-on-mobile {
      display: none;
    }
    p {
      font-size: 16px;
    }
    h4{
        font-size: 1.2em;
    }
    .hero-heading{
      position: absolute;
      top: 50%; /* Adjust as needed */
      color: #800e1d;
      left: 50%; /* Adjust as needed */
      transform: translate(-50%, -50%); /* Center the overlay */
      padding: 0;
      font-size: 1.5em;
  }
    .hero-img{
      max-width: 100%;
      height: 160px;
    }
    .card:hover{
      transform: none;
    }
    .content-col{
      padding: 5%;
    }
  }
  
  @media (min-width: 769px) and (max-width: 991px) {
    .hide-on-mobile {
      display: inline-block;
    }
    .hide-on-desktop {
      display: none;
    }
    .hero-heading {
      font-size: 1.2em;
    }
    .card:hover{
      transform: none;
    }
  }
  
  @media (min-width: 951px) and (max-width: 1600px) {
    .hero-heading {
      font-size: 3.5em; /* Adjust the font size as desired */
    }
  }
  
  @media (min-width: 1501px) {
    .hide-on-desktop {
      display: none;
    }
  }
  